import '../assets/styles/skills.scss';

function DevOpsSkills() {
    return (
        <section className="devops-skills skills p-4 p-md-5">
            <h3>DevOps Skills</h3>
            <hr />
            
            <ul>
                <li>Version Control systems</li>
                <li>Git, SVN</li>
                <li>Server administration</li>
                <li>Cloud provider proficiency, AWS, Azure, Google Cloud</li>
                <li>Apache/Nginx administration</li>
                <li>Caching systems, Varnish, Redis</li>
                <li>Web Application Firewalls (WAF)</li>
                <li>CI/CD tools</li>
                <li>Automated compile/deployment</li>
                <li>BuddyWorks, DeployHQ, CircleCI, Jenkins, Gitlab CI/CD</li>
                <li>Gulp, Grunt, Webpack, Sass</li>
                <li>Package management tools</li>
                <li>Composer, NPM, Yarn, Node</li>
            </ul>
        </section>
    );
}

export default DevOpsSkills;
