function Contents() {
    return (
        <section className="sidebar__contents">
            <h2>Contents</h2>
            <hr />

            <ul>
                <li><a href="/personal-statement">Personal statement</a></li>
                <li>
                    <a href="/skills">Skills</a>
                    <ul>
                        <li><a href="/skills/development-skills">Development skills</a></li>
                        <li><a href="/skills/devops-skills">DevOps skills</a></li>
                        <li><a href="/skills/other-skills">Management skills</a></li>
                        <li><a href="/skills/projects">Personal projects</a></li>
                    </ul>
                </li>
                <li><a href="/employment">Employment</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul>
        </section>
    );
}

export default Contents;