import '../assets/styles/sidebar.scss';
import profileImage from '../images/mike-cropped.jpg';
import ContactIcons from './sidebar__contact-icons';
import Contents from './sidebar__contents';
import Social from './sidebar__social';

function Sidebar() {
  return (
    <aside className="col-12 col-md-3 sidebar p-5 p-md-3 p-lg-5">
        <img src={profileImage} className="profile-image" alt="Mike Bricknell-Barlow" />
        <ContactIcons />
        <Contents />
        <Social />
    </aside>
  );
}

export default Sidebar;
