import '../assets/styles/intro.scss';

function Intro() {
    return (
        <section className="main__intro intro px-4 py-5 p-md-5">
            <h1>Mike <strong>Bricknell-Barlow</strong></h1>

            <p className="intro__job">Senior Developer - <a href="https://www.investoogroup.com/" target="_blank">Investoo Group Limited</a></p>
        
            <div className="intro__blurb">
                <p>An accomplished, professional web developer with extensive commercial experience, in both agency and a personal capacity. Comfortable working in fast paced environments, in senior/management positions, or where I can share knowledge and skills.</p>
            </div>
        </section>
    );
}

export default Intro;
