function Elementary() {
    return (
        <div className="employment__employer p-3">
            <h3>Senior Full-stack Developer</h3>
            <hr />
            
            <div className="employment__meta">
                <p>Elementary Digital Limited</p>
                <p>April 2018 - August 2020</p>
            </div>
            <hr />

            <div className="employment__meta employment__technologies">
                <div>
                    <p><strong>Technologies used</strong></p>
                </div>
                <div className="right">
                    <span>PHP</span>
                    &middot;
                    <span>WordPress</span>
                    &middot;
                    <span>Laravel</span>
                    &middot;
                    <span>React</span>
                    &middot;
                    <span>API Integrations</span>
                    &middot;
                    <span>DevOps</span>
                </div>
            </div>
            <hr />

            <div className="employment__summary">
                <p>This role was primarily WordPress development focused, with responsibilities aimed at delivering clean, best practice builds from design sign-off to completion.</p>

                <p>Within this role I developed a deeper understanding of developmental techniques and methodologies, including the agile method of software development. I also gained exposure to "Dev-ops" techniques such as automated deployment from version control systems, more advanced Git flow techniques, and collaborative development methods.</p>

                <br />
                <p><strong>Projects of note</strong></p>

                <ul>
                    <li><a href="https://greatnorthairambulance.co.uk" target="_blank">The Great North Air Ambulance</a> | PHP | Laravel | WordPress | API Integrations</li>
                    <li><a href="https://www.pinknews.co.uk" target="_blank">Pink News</a> | PHP | WordPress</li>
                    <li><a href="https://iaato.org" target="_blank">The International Association of Antartic Tour Operators</a> | PHP | WordPress</li>
                    <li><a href="https://www.theefficientboilercompany.co.uk" target="_blank">The Efficient Boiler Company</a> | PHP | Laravel | React | WordPress | API Integrations</li>
                </ul>

                <br />
                <p><strong>Summary of responsibilities</strong></p>

                <div className="employment__responsibilites">
                    <ul>
                        <li>Project development</li>
                        <li>Routine project support</li>
                        <li>Client liaison</li>
                        <li>Writing/maintaining documentation</li>
                        <li>System admin assistance</li>
                    </ul>

                    <ul>
                        <li>Server maintenance</li>
                        <li>Sensitive updates</li>
                        <li>Issue monitoring</li>
                        <li>Scoping/ technical specifications</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Elementary;