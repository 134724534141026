import '../assets/styles/employment.scss';
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Investoo from './employers/investoo';
import Granite5 from './employers/granite5';
import Elementary from './employers/elementary';
import HWT from './employers/hwt';
import MBBWebServices from './employers/mbbwebservices';
import Codemasters from './employers/codemasters';
import {ReactComponent as Arrow} from '../images/arrow.svg';

class Employment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 5,
        };
    }

    PrevButton() {
        let classNames = 'arrow';

        if( this.state.currentSlide <= 0 ) {
            classNames += ' disabled';
        }
        
        return (
            <a className={ classNames } onClick={this.prev}><Arrow /> See less recent jobs</a>
        );
    }

    NextButton() {
        if( this.state.currentSlide < 5 ) {
            return (
                <a className="arrow next" onClick={this.next}>See more recent jobs <Arrow /></a>
            );
        }
    }

    next = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide + 1,
        }));
    };

    prev = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide - 1,
        }));
    };

    render() {
        return (
            <section className="employment px-4 py-5 p-md-5">
                <h2>Employment History</h2>
                <hr />
    
                <div className="employment__employers">
                    <div className="employment__controls">
                        {this.PrevButton()}
                        {this.NextButton()}
                    </div>

                    <Carousel selectedItem={this.state.currentSlide}>
                        <Codemasters />
                        <MBBWebServices />
                        <HWT />
                        <Elementary />
                        <Granite5 />
                        <Investoo />
                    </Carousel>
                </div>
            </section>
        );
    }
}

export default Employment;
