function MBBWebServicecs() {
    return (
        <div className="employment__employer p-3">
            <h3>Web Developer</h3>
            <hr />
            
            <div className="employment__meta">
                <p>MBB Web Services - Freelance</p>
                <p>February 2014 - July 2016</p>
            </div>
            <hr />

            <div className="employment__summary">
                <p>In February 2014, I began my education in web development skills and techniques. I used a variety of online resources 
                and tutorials in order to teach myself web development, starting with the very basics, and working my way up through continued
                study and projects for myself, for friends, and for family.
                </p>

                <p>In May 2015, I began offering my freelance services by starting my own business, MBB Web Services. I provided web design and development services to
                individuals and businesses across North Wales and Anglesey, where I was based. During this time, I worked with
                numerous clients each requiring different results from our collaboration.</p>

                <p>Working for myself allowed me to develop my skills, not only my technical skills in web design and development, but also 
                my skills in time management, self-motivation, and client relationships.</p>
            </div>
        </div>
    );
}

export default MBBWebServicecs;