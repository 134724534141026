import {ReactComponent as Twitter} from '../images/twitter.svg';
import {ReactComponent as Github} from '../images/github.svg';
import {ReactComponent as LinkedIn} from '../images/linkedin.svg';
import {ReactComponent as WordPress} from '../images/wordpress.svg';
import '../assets/styles/social.scss';

function Social() {
    return (
        <section className="sidebar__social social">
            <div className="social__wrapper">
                <a href="https://twitter.com/MikeBB1989" target="_blank">
                    <Twitter />
                </a>

                <a class="github-icon" href="https://github.com/mike-bricknell-barlow" target="_blank">
                    <Github />
                </a>

                <a href="https://www.linkedin.com/in/michael-bricknell-barlow-1a3829b3/" target="_blank">
                    <LinkedIn />
                </a>

                <a href="https://profiles.wordpress.org/mikebricknellbarlow/" target="_blank">
                    <WordPress />
                </a>
            </div>
        </section>
    );
}

export default Social;