function Investoo() {
    return (
        <div className="employment__employer p-3">
            <h3>Senior Developer</h3>
            <hr />
            
            <div className="employment__meta">
                <p>Investoo Group Limited</p>
                <p>December 2021 - Present</p>
            </div>
            <hr />

            <div className="employment__meta employment__technologies">
                <div>
                    <p><strong>Technologies used</strong></p>
                </div>
                <div className="d-block d-xl-flex right">
                    <span className="tech">PHP</span>
                    <span className="dot">&middot;</span>
                    <span className="tech">WordPress</span>
                    <span className="dot">&middot;</span>
                    <span className="tech">Node JS</span>
                    <span className="dot">&middot;</span>
                    <span className="tech">API Integrations</span>
                    <span className="dot">&middot;</span>
                    <span className="tech">DevOps</span>
                </div>
            </div>
            <hr />

            <div className="employment__summary">
                <p>
                    I joined Investoo Group Limited in December 2021, during which time the company was undergoing a process of internal restructuring. As a relatively young company, around 5 years old at my time of joining, 
                    and with their goal of continued growth and expansion, a lot of work was needed to overhaul their existing technologies to both improve the experience for the non-technical teams within the company, as well
                    as improving the scalability and maintainability of the systems from a developer perspective. The company was very keen to make use of my experience as a WordPress developer as well as my leadership/mentoring skills.
                </p>

                <p>
                    My role with Investoo Group has evolved rapidly - initially joining an established team of developers, within 4 months of starting at the company, due to internal restructuring, I was given the company's
                    single largest, most valuable website, <a href="https://invezz.com">Invezz</a>, to be solely managed by me. I am now the sole developer managing this high-traffic, high-converting website, maintaining an existing 
                    codebase, correcting long-standing bugs and technical issues, as well as rapidly iterating on new developments and features.
                </p>

                <p>
                    As an affiliate marketing company, a lot of company revenue is tied to the reliability of the websites used to drive affiliate clicks. In this role, I am responsible for supporting 30+ high-traffic websites,
                    and ensuring these are performant, achieve a high level of up-time, and are well optimised from an SEO and conversion stand point.
                </p>
                
                <p><strong>Projects of note</strong></p>

                <ul>
                    <li><a href="https://invezz.com/" target="_blank">Invezz</a> | PHP | WordPress | API Integrations</li>
                    <li><a href="https://coinjournal.net/" target="_blank">Coin Journal</a> | PHP | WordPress | API Integrations</li>
                    <li><a href="https://cointext.com/" target="_blank">Coin Text</a> | PHP | WordPress | API Integrations</li>
                </ul>

                <br />
                <p><strong>Summary of responsibilities</strong></p>

                <div className="employment__responsibilites">
                    <ul>
                        <li>Setting technical direction for new features/projects</li>
                        <li>Supporting and improving existing infrastructure</li>
                        <li>Setting up and maintaining DevOps systems</li>
                        <li>Supporting internal SEO and CRO teams to directly improve company revenue</li>
                    </ul>

                    <ul>
                        <li>Set deployment procedures</li>
                        <li>Set or suggest procedural improvements for related tasks</li>
                        <li>Sensitive updates</li>
                        <li>Issue monitoring</li>
                        <li>Support/mentor other developers in the team as required</li>
                    </ul>

                    <ul>
                        <li>Inter-company communication</li>
                        <li>Client liaison</li>
                        <li>Writing/maintaining documentation</li>
                        <li>System admin assistance</li>
                        <li>Server maintenance</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Investoo;
