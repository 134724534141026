import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/font.scss';
import Sidebar from './templates/sidebar';
import Main from './templates/main';
import reportWebVitals from './reportWebVitals';
import './assets/scripts/preload';

ReactDOM.render(
  <React.StrictMode>
    <div className="container-fluid">
      <div className="row">
        <Sidebar />  
        <Main />
      </div>
    </div> 
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
