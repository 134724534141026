function HWT() {
    return (
        <div className="employment__employer p-3">
            <h3>Senior Web Developer</h3>
            <hr />
            
            <div className="employment__meta">
                <p>HW Technology Limited</p>
                <p>June 2016 - March 2018</p>
            </div>
            <hr />

            <div className="employment__meta employment__technologies">
                <div>
                    <p><strong>Technologies used</strong></p>
                </div>
                <div className="right">
                    <span>PHP</span>
                    &middot;
                    <span>WordPress</span>
                    &middot;
                    <span>Laravel</span>
                    &middot;
                    <span>jQuery</span>
                    &middot;
                    <span>mySql</span>
                </div>
            </div>
            <hr />

            <div className="employment__summary">
                <p>In this role, I was working as part of a small team, and later, I began to lead a new team due to staffing changes. This role allowed me to greatly expand my web development skills in an agency setting, and develop my team-leading and project management skills.</p>
            
                <br />
                <p><strong>Projects of note</strong></p>

                <ul>
                    <li><a href="https://www.hwca.com" target="_blank">Haines Watts</a></li>
                </ul>

                <br />
                <p><strong>Summary of responsibilities</strong></p>

                <div className="employment__responsibilites">
                    <ul>
                        <li>Client proposals</li>
                        <li>First line support</li>
                        <li>Project development</li>
                        <li>Routine project support</li>
                        <li>Client liaison</li>
                        <li>Writing/maintaining documentation</li>
                    </ul>

                    <ul>
                        <li>System admin assistance</li>
                        <li>Co-worker mentoring</li>
                        <li>Server maintenance</li>
                        <li>Sensitive updates</li>
                        <li>Issue monitoring</li>
                        <li>Scoping/ technical specifications</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default HWT;