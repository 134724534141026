import React from 'react';
import Intro from "./main__intro";
import PersonalStatement from "./main__personal-statement";
import Skills from "./main__skills";
import DevelopmentSkills from './main__development-skills';
import DevOpsSkills from './main__devops-skills';
import SoftSkills from './main__other-skills';
import PersonalProjects from './main__personal-projects';
import Employment from './main__employment';
import Contact from './main__contact';
import '../assets/styles/main.scss';

class Main extends React.Component {
  ContentSection() {    
    switch( window.location.pathname ) {
      case '/skills': 
        return <Skills />;

      case '/skills/development-skills':
        return <DevelopmentSkills />

      case '/skills/devops-skills':
        return <DevOpsSkills />

      case '/skills/other-skills':
        return <SoftSkills />

      case '/skills/projects':
        return <PersonalProjects />

      case '/employment':
        return <Employment />

      case '/contact':
        return <Contact />

      default: 
        return <PersonalStatement />;
    }
    
  }

  componentDidMount() {
    window.addEventListener('load', this.scrollToMain);
  }

  scrollToMain() {
    if( window.innerWidth < 768 && window.location.pathname != '/' ) {
      document.querySelector('.main-wrapper').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
  
  render() {
    return (
      <main className="col-12 col-md-9 main-wrapper">
          <Intro />
          {this.ContentSection()}
      </main>
    );
  }
}
  
  
export default Main;
  