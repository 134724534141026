import '../assets/styles/skills.scss';

function OtherSkills() {
    return (
        <section className="other-skills skills p-4 p-md-5">
            <h3>Management Skills</h3>
            <hr />
            
            <ul>
                <li>Interpersonal skills</li>
                <li>Communication</li>
                <li>Organisation and delegation</li>
                <li>Forward planning and strategic thinking</li>
                <li>Problem solving and decision-making</li>
                <li>Mentoring</li>
                <li>Project scoping</li>
            </ul>
        </section>
    );
}

export default OtherSkills;
