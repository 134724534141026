import '../assets/styles/skills.scss';

function DevelopmentSkills() {
    return (
        <section className="development-skills skills p-4 p-md-5">
            <h3>Development Skills</h3>
            <hr />
            
            <ul>
                <li>PHP Development</li>
                <li>Laravel</li>
                <li>JavaScript</li>
                <li>React</li>
                <li>Node JS</li>
                <li>PWAs & Service workers</li>
                <li>jQuery</li>
                <li>VueJS</li>
                <li>mySQL</li>
                <li>API Integrations</li>
                <li>Docker</li>
                <li>HTML5, CSS3</li>
            </ul>
        </section>
    );
}

export default DevelopmentSkills;
