function PersonalStatement() {
    return (
        <section className="personal-statement px-4 py-5 p-md-5">
            <h2>Personal statement</h2>

            <hr />

            <p>A motivated and punctual employee, my previous employers have described me as flexible, co-operative, and unpretentious, with great initiative and drive. I am efficient and organised, used to implementing and working to best-practice workflows. I enjoy working in a leadership position within a team, but I am equally content alone as individual tasks require.</p>

            <p>As a full-stack developer I have extensive experience across a range of languages and frameworks, my preference being Wordpress and Laravel, and I have created several plugins for the WordPress platform. Examples of my code have also been stored in the <a href="https://medium.com/@shubhamrattra/everything-you-need-to-know-about-github-arctic-code-vault-ba19dd2a3ec8" target="_blank">Github Arctic Code Vault project</a>. It is important to me that I keep up with new best practices, and I enjoy experimenting with new languages and frameworks to improve my skills and knowledge in my own time. I am also undertaking part-time study for a Bachelor's degree in Computer Science.</p>

            <p>In my current role, I utilise my extensive experience in WordPress to support and build a number of high-traffic, high-ranking websites in the Cryptocurrency market for my employer, Investoo Group Limited.</p>
        </section>
    );
}

export default PersonalStatement;
