import DevelopmentSkills from './main__development-skills';
import DevOpsSkills from './main__devops-skills';
import SoftSkills from './main__other-skills';
import PersonalProjects from './main__personal-projects';
import '../assets/styles/skills.scss';

function Skills() {
    return (
        <section className="all-skills skills p-3 p-md-5">
            <h2>Skills</h2>
            <hr />

            <div className="skills__wrapper">
                <SoftSkills />
                <DevelopmentSkills />
                <DevOpsSkills />
                <PersonalProjects />
            </div>
        </section>
    );
}

export default Skills;
