function Granite5() {
    return (
        <div className="employment__employer p-3">
            <h3>Lead Developer</h3>
            <hr />
            
            <div className="employment__meta">
                <p>Granite 5 Limited</p>
                <p>August 2020 - December 2021</p>
            </div>
            <hr />

            <div className="employment__meta employment__technologies">
                <div>
                    <p><strong>Technologies used</strong></p>
                </div>
                <div className="right">
                    <span>PHP</span>
                    &middot;
                    <span>WordPress</span>
                    &middot;
                    <span>Laravel</span>
                    &middot;
                    <span>Docker</span>
                    &middot;
                    <span>API Integrations</span>
                    &middot;
                    <span>DevOps</span>
                </div>
            </div>
            <hr />

            <div className="employment__summary">
                <p>I joined Granite5 in August as Lead Developer, with the aim of improving their internal processes and setting a new, improved technical direction in order to support larger clients with more complex requirements.</p>

                <p>Within my 16 months in the role I was able to implement a number of processes which has allowed us to attract bigger clients and more technically advanced projects. These processes include:</p>

                <ul>
                    <li>Best practice version control workflows</li>
                    <li>Automated deployment systems</li>
                    <li>Automated testing infrastructure</li>
                    <li>An in-house modular theme framework</li>
                </ul>

                <br />
                <p><strong>Projects of note</strong></p>

                <ul>
                    <li><a href="https://www.trademarkeagle.co.uk" target="_blank">Trademark Eagle</a> (new web app currently under construction) | PHP | Laravel</li>
                    <li><a href="https://www.bsg.org.uk" target="_blank">The British Society of Gastroenterology</a> | PHP | WordPress | API Integrations</li>
                    <li><a href="https://thepbsl.com/" target="_blank">The Professional Body of Sports Leadership</a> | PHP | WordPress</li>
                    <li><a href="https://onlinehearingcare.co.uk/" target="_blank">Online Hearing Care</a> | PHP | WordPress</li>
                    <li><a href="https://fflm.ac.uk/" target="_blank">The Faculty of Forensic & Legal Medicine</a> | PHP | WordPress | API Integrations</li>
                    <li><a href="https://www.associationfornutrition.org/" target="_blank">The Association for Nutrition</a> | PHP | WordPress | API Integrations   </li>
                </ul>

                <br />
                <p><strong>Summary of responsibilities</strong></p>

                <div className="employment__responsibilites">
                    <ul>
                        <li>Project management</li>
                        <li>Set general technical direction</li>
                        <li>Routine project work</li>
                        <li>Scoping/technical specifications</li>
                        <li>Routine support work</li>
                        <li>Set version control practices</li>
                    </ul>

                    <ul>
                        <li>Set deployment procedures</li>
                        <li>Set or suggest procedural improvements for related tasks</li>
                        <li>Sensitive updates</li>
                        <li>Issue monitoring</li>
                        <li>Support/mentor other developers in the team as required</li>
                    </ul>

                    <ul>
                        <li>Inter-company communication</li>
                        <li>Client liaison</li>
                        <li>Writing/maintaining documentation</li>
                        <li>System admin assistance</li>
                        <li>Server maintenance</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Granite5;
