import {ReactComponent as Email} from '../images/email.svg';
import {ReactComponent as Phone} from '../images/phone.svg';
import {ReactComponent as Web} from '../images/web.svg';

function ContactIcons() {
    return (
        <section className="sidebar__contact-icons d-flex justify-content-center">
            <div>
                <a href="mailto:mike@bricknellbarlow.co.uk" target="_blank">
                    <span className="sidebar__contact-icon">
                        <Email />
                    </span>
                </a>
            </div>

            <div>
                <a href="tel:07462285550" target="_blank">
                    <span className="sidebar__contact-icon sidebar__phone-icon">
                        <Phone />
                    </span>
                </a>
            </div>

            <div>
                <a href="https://bricknellbarlow.co.uk" target="_blank">
                    <span className="sidebar__contact-icon">
                        <Web />
                    </span>
                </a>
            </div>
        </section>
    );
}

export default ContactIcons;