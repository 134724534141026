function Codemasters() {
    return (
        <div className="employment__employer p-3">
            <h3>Quality Assurance Technician</h3>
            <hr />
            
            <div className="employment__meta">
                <p>Codemasters Software</p>
                <p>July 2012 - January 2013</p>
            </div>
            <hr />

            <div className="employment__summary">
                <p>In July 2012, I was employed by Codemasters Software Limited, on a temporary 3-month contract, as a QA technician.</p>

                <p>In this role, I was required to test pre-release video games under development by
                the company, following a test plan to discover bugs and faults in the game code. I would then
                be required to report these bugs and record them on the company database, and later to
                review, and verify these as fixed.</p>

                <p>Quality Assurance Technicians at this time were always hired on temporary contracts due to the infrequent nature of the
                product release windows, however despite originally being taken on for 3 months, this was later extended by a further 3 months
                due to my performance.</p>

                <p>This role helped to stoke my passion in the software development industry, and provided valuable insight into how large-scale
                software projects, such as game development, take place. The high attention-to-detail required by this role helped me to develop
                that skill, invaluable in the wider development industry.</p>
            </div>
        </div>
    );
}

export default Codemasters;