import {ReactComponent as Email} from '../images/email.svg';
import {ReactComponent as Phone} from '../images/phone.svg';
import {ReactComponent as Web} from '../images/web.svg';
import {ReactComponent as Form} from '../images/form.svg';
import '../assets/styles/contact.scss';

function Contact() {
    return (
        <section className="contact px-4 py-5 p-md-5">
            <p>If you'd like to get in touch with me, please use the details below.</p>

            <div className="contact__details">
                <div>
                    <a href="https://bricknellbarlow.co.uk/get-in-touch/" target="_blank">
                        <span className="sidebar__contact-icon contact__contact-icon">
                            <Form />
                        </span>
                        <p>Contact form</p>
                    </a>
                </div>

                <div>
                    <a href="mailto:mike@bricknellbarlow.co.uk" target="_blank">
                        <span className="sidebar__contact-icon contact__contact-icon">
                            <Email />
                        </span>
                        <p>mike@bricknellbarlow.co.uk</p>
                    </a>
                </div>

                <div>
                    <a href="tel:07462285550" target="_blank">
                        <span className="sidebar__contact-icon contact__contact-icon phone-icon">
                            <Phone />
                        </span>
                        <p>07462 285550</p>
                    </a>
                </div>

                <div>
                    <a href="https://www.bricknellbarlow.co.uk" target="_blank">
                        <span className="sidebar__contact-icon contact__contact-icon">
                            <Web />
                        </span>
                        <p>www.bricknellbarlow.co.uk</p>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Contact;
