import '../assets/styles/skills.scss';

function PersonalProjects() {
    return (
        <section className="personal-projects skills p-4 p-md-5">
            <h3>Personal Projects</h3>
            <hr />

            <p><strong>WordPress plugins</strong></p>
            <p><a href="https://wordpress.org/plugins/simple-webp-images/" target="_blank">Simple Webp Images</a></p>
            <p><a href="https://wordpress.org/plugins/manual-related-products-for-woo/" target="_blank">Manual Related Products for WooCommerce</a></p>
            
            <p><strong>Github repositories</strong></p>
            <p><a href="https://github.com/mike-bricknell-barlow/wp-convert-api-integration" target="_blank">ConvertAPI Integration for WordPress (in-progress)</a></p>
            <p><a href="https://github.com/mike-bricknell-barlow/wordpress-simple-html-cache" target="_blank">WordPress Simple HTML Cache (in-progress)</a></p>
        </section>
    );
}

export default PersonalProjects;
